import * as jose from 'jose'
import { JWTPayload } from "jose";

function createDataValue(idToken: JWTPayload, field: string) {
    const value = document.createElement("p")
    value.className = "jwt-data-value"
    value.textContent = `${idToken[field]}`
    return value;
}

function createDataRow(field: string) {
    const name = document.createElement("p")
    name.className = "jwt-data-name"
    name.textContent = `${field}:`
    return name;
}

function renderIdTokenContents() {
    const idTokenQueryParam = new URLSearchParams(window.location.search).get("id_token")

    if (!idTokenQueryParam) {
        return
    }

    const idToken = jose.decodeJwt(idTokenQueryParam)

    const container = document.getElementById("jwt-container")

    const header = document.createElement("h2")
    header.textContent = "ID-token contents"
    container.append(header)

    for (const field in idToken) {
        const name = createDataRow(field);
        const value = createDataValue(idToken, field);
        const br = document.createElement('br')
        container.append(name)
        container.append(value)
        container.append(br)
    }
}

function renderButtons() {
    const idTokenQueryParam = new URLSearchParams(window.location.search).get("id_token")

    if (!idTokenQueryParam) {
        document.getElementById("logout").style.display = "none"
        return
    }

    document.getElementById("login").style.display = "none"
}

renderIdTokenContents()
renderButtons()